.card {
    padding: 10px;
    border-radius: 15px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    opacity: 80%;
}

.menu {
    text-wrap: nowrap   ;
}

li, a{
    margin-right: 20px;
    text-align:left;
    inline-size: none;
    color: white;
    font-size: 20px;
    text-decoration: none;
}

ul {
    list-style-type: none;
    border-radius: 5%;
}


@media (max-width: 768px) {
    .menu {
        text-wrap: nowrap   ;
        display: flex;
        text-wrap: nowrap;
        list-style: none;
        padding-left: 0px;
        margin: 0;
    }

    .start{
        border: solid 1px white;
    }
    .menu a{
        text-align:left;
        inline-size: none;
        color: white;
        font-size: 3rem;
        padding: 0.5rem;
        text-decoration: none;
        max-width: 90%;
        box-sizing: border-box;
    }


}

@media (max-width: 480px) {
    .menu {
        display: flex;
        text-wrap: nowrap;
        list-style: none;
        padding-left: 0px;
        margin: 0;
    }

    .menu a {
        font-size: 2.0rem;
        max-width: 100%;
    }
    .container {
        display: flex;
        flex: 1;
    }

    .start{
        border: solid 1px white;
        display: block;
        padding: 1rem;
        color: white;
        text-decoration: none;
        border-bottom: 1px solid #ddd;
    }
    .menu li {
        padding-left: 5px;
        margin: -7px;
    }
    .content {
        flex: 1;
    }

    #start {
        margin-bottom: -50px;
    }
}